// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-founder-js": () => import("./../../../src/pages/founder.js" /* webpackChunkName: "component---src-pages-founder-js" */),
  "component---src-pages-ignored-page-js": () => import("./../../../src/pages/ignored-page.js" /* webpackChunkName: "component---src-pages-ignored-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-trainings-js": () => import("./../../../src/pages/trainings.js" /* webpackChunkName: "component---src-pages-trainings-js" */),
  "component---src-template-article-test-js": () => import("./../../../src/template/article-test.js" /* webpackChunkName: "component---src-template-article-test-js" */),
  "component---src-template-services-catalog-js": () => import("./../../../src/template/services-catalog.js" /* webpackChunkName: "component---src-template-services-catalog-js" */),
  "component---src-template-services-inner-js": () => import("./../../../src/template/services-inner.js" /* webpackChunkName: "component---src-template-services-inner-js" */),
  "component---src-template-training-default-js": () => import("./../../../src/template/training-default.js" /* webpackChunkName: "component---src-template-training-default-js" */)
}

